




















































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import AnswerMultiple from "@/views/Student/LearnStatus/PreExamsList/AnswerMultiple.vue";
import AnswerFree from "@/views/Student/LearnStatus/PreExamsList/AnswerFree.vue";
import FscModal from "@/components/Modal/FscModal.vue";

@Component({
  components: {
    FscModal,
    AnswerMultiple,
    AnswerFree,
  },
})
export default class PreExamsListAnswers extends Vue {
  public name = "PreExamsListAnswers";

  @Prop()
  public question!: any;

  @Prop()
  public answer!: any;

  @Prop()
  public licenseClassName!: any;

  public questionClass: any = "";

  public isMultiple: any = null;

  public questionVideoId: any = "question-video-id";
  public thumbnail: any = "START";

  public mounted() {
    this.isMultiple = this.isMultipleChoice(this.question);
    this.questionClassName(this.question);
  }

  public isMultipleChoice(question: any) {
    return question.questionType === "MULTIPLE_CHOICE";
  }

  public questionClassName(question: any): void {
    switch (question.questionClass) {
      case "G":
        this.questionClass = "Grundstoff";
        break;
      case "GM":
        this.questionClass = "Grundstoff";
        break;
      case "G,GM":
        this.questionClass = "Grundstoff";
        break;
      default:
        this.questionClass = this.licenseClassName ? this.licenseClassName : "";
    }
  }

  @Watch("question")
  public onQuestionChange(question: any): void {
    if (question) {
      this.questionClassName(question);
      this.isMultiple = this.isMultipleChoice(question);
    }
  }

  public get mediaUrl(): string {
    if (this.question.withPicture) {
      return `${process.env.VUE_APP_BACKEND_BASE_URL}/theory-questions/${this.question.id}/media`;
    }
    return `${process.env.VUE_APP_BACKEND_BASE_URL}/theory-questions/${this.question.id}/media?thumbnail=${this.thumbnail}`;
  }

  public get videoUrl(): string {
    return `${process.env.VUE_APP_BACKEND_BASE_URL}/theory-questions/${this.question.id}/media`;
  }

  private get isMediaQuestion(): boolean {
    return this.isMediaVideo || this.question.withPicture;
  }

  private get isMediaVideo(): boolean {
    return this.question.withVideo;
  }

  private showVideo(): void {
    this.$bvModal.show(this.questionVideoId);
  }

  private onCloseVideo() {
    this.thumbnail = "END";
  }
}
